<template>
    <v-card class="container-modul">
        <v-card-title class="pa-0" ><v-icon class="mr-2" color="blackIcon">mdi-account-tie</v-icon>Employee Info</v-card-title>
            <v-form
                ref="employeeInfoForm"
                v-model="valid"
                lazy-validation
            >
                <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-menu
                        v-if="mode.view == 'admin'"
                        v-model="modaldob"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="employeeInfo.dateofemployment"
                                label="Date Of Employment"
                                prepend-icon="mdi-calendar"
                                outlined
                                dense
                                hide-details="auto"
                                v-bind="attrs"
                                v-on="on"
                                :readonly="mode.view == 'user'"
                                :rules="[v => !!v || 'Please select date of employment']"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="employeeInfo.dateofemployment"
                            @input="modaldob = false"
                            ></v-date-picker>
                        </v-menu>

                        <v-text-field
                            v-else
                            v-model="employeeInfo.dateofemployment"
                            label="Date Of Employment"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        
                        </v-col>

                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-select
                            v-model="employeeInfo.personalgradeid"
                            :items="personalGrade"
                            item-text="name"
                            item-value="idpersonalgrade"
                            label="Personal Grade"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-select>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="employeeInfo.position"
                            label="Position"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        <!-- <v-select
                            v-model="employeeInfo.position"
                            :items="position"
                            item-text="name"
                            item-value="idposition"
                            label="Position"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-select> -->
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-select
                            v-model="employeeInfo.systemroleid"
                            :items="systemRole"
                            item-text="name"
                            item-value="iduseraccess"
                            label="System Role"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                            :rules="[v => !!v || 'Please select system role']"
                        ></v-select>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-select
                            v-model="employeeInfo.companyid"
                            :items="company"
                            item-text="name"
                            item-value="idcompany"
                            label="Company"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-select>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="employeeInfo.staffnum"
                            label="Staff Number"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-select
                            v-model="employeeInfo.employtypeid"
                            :items="typeemployment"
                            item-text="name"
                            item-value="idemploymenttype"
                            label="Type Of Employment"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-select>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="employeeInfo.cardnum"
                            label="Card Access Number"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- <v-row 
                        v-if="employeeInfo.employtypeid == 2"
                    >
                        <v-col
                        cols="12"
                        sm="6"
                        >
                            <v-menu
                                v-if="mode.view == 'admin'"
                                v-model="modalstartcontract"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="employeeInfo.startcontractdt"
                                    label="Date of Contract Start"
                                    prepend-icon="mdi-calendar"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    v-bind="attrs"
                                    v-on="on"
                                    :readonly="mode.view == 'user'"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="employeeInfo.startcontractdt"
                                @input="modalstartcontract = false"
                                ></v-date-picker>
                            </v-menu>
                            <v-text-field
                                v-else
                                v-model="employeeInfo.startcontractdt"
                                label="Date of Contract Start"
                                outlined
                                dense
                                hide-details="auto"
                                :readonly="mode.view == 'user'"
                            ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        >
                            <v-menu
                                v-if="mode.view == 'admin'"
                                v-model="modalendcontract"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="employeeInfo.endcontractdt"
                                    label="Date of Contract End"
                                    prepend-icon="mdi-calendar"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    v-bind="attrs"
                                    v-on="on"
                                    :readonly="mode.view == 'user'"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="employeeInfo.endcontractdt"
                                @input="modalendcontract = false"
                                ></v-date-picker>
                            </v-menu>

                            <v-text-field
                                v-else
                                v-model="employeeInfo.endcontractdt"
                                label="Date of Contract End"
                                outlined
                                dense
                                hide-details="auto"
                                :readonly="mode.view == 'user'"
                            ></v-text-field>
                        </v-col>
                    </v-row> -->
                    
                    <v-row v-if="mode.view == 'admin'">
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <!-- <v-text-field
                            v-model="employeeInfo.supervisorname"
                            label="Supervisor"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field> -->

                        <v-autocomplete
                            v-model="employeeInfo.supervisorid"
                            :items="stafflist"
                            item-text="name"
                            item-value="idemp"
                            label="Supervisor"
                            outlined
                            dense
                            hide-details="auto"
                        ></v-autocomplete>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <!-- <v-text-field
                            v-model="employeeInfo.superiorname"
                            label="Superior"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field> -->

                        <v-autocomplete
                            v-model="employeeInfo.superiorid"
                            :items="stafflist"
                            item-text="name"
                            item-value="idemp"
                            label="Superior"
                            outlined
                            dense
                            hide-details="auto"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-if="mode.view == 'user'">
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="employeeInfo.supervisorname"
                            label="Supervisor"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="employeeInfo.superiorname"
                            label="Superior"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="employeeInfo.totalannualleave"
                            label="Total Annual Leave"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="employeeInfo.countcarryforward"
                            label="Total Carry Forward Leave"
                            outlined
                            dense
                            hide-details="auto"
                            readonly
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="employeeInfo.annualleaveused"
                            label="Total Annual Leave Applied"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="employeeInfo.totalmedicalleave"
                            label="Total Medical Leave"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="employeeInfo.medicalleaveused"
                            label="Total Medical Leave Applied"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="employeeInfo.annualleaveadjustment"
                            label="Annual Leave Adjustment"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="4"
                        >
                        
                        <v-menu
                            v-if="mode.view == 'admin'"
                            v-model="modaleob"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="employeeInfo.endofemployment"
                                label="End Of Employment"
                                prepend-icon="mdi-calendar"
                                outlined
                                dense
                                hide-details="auto"
                                v-bind="attrs"
                                v-on="on"
                                :readonly="mode.view == 'user'"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="employeeInfo.endofemployment"
                            @input="modaleob = false"
                            ></v-date-picker>
                        </v-menu>
                        
                        <v-text-field
                            v-else
                            v-model="employeeInfo.endofemployment"
                            label="End Of Employment"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>

                        </v-col>
                    </v-row>

                    <v-row v-if="mode.view == 'admin'">
                        <v-btn
                        class="mr-4"
                        color="primary"
                        @click="updateEmployeeInfo()"
                        >
                        Update
                        </v-btn>
                        <!-- <v-btn
                        color="error"
                        disabled
                        >
                        Reset
                        </v-btn> -->
                    </v-row><br>
                    
                </v-container>
            </v-form>
            
            <v-divider  v-if="employeeInfo.empstatus == 'CONTRACT' || employeeInfo.empstatus == 'TRAINEE'"></v-divider>

            <v-container v-if="employeeInfo.empstatus == 'CONTRACT' || employeeInfo.empstatus == 'TRAINEE'">
                <v-card-subtitle class="pa-0" >
                    <v-icon color="dark-grey" class="mr-1">mdi-school-outline</v-icon> Contract Info
                </v-card-subtitle>
                <!-- <div class="text-subtitle-2 mb-2">Educational Info</div> -->
                <v-card-text class="mt-2">
                    <v-row>
                        <template>
                            <v-container>
                                <v-data-table
                                :headers="headersContract"
                                :items="employeeInfo.kontraklist"
                                :items-per-page="5"
                                class="elevation-1"
                                >
                                <template v-slot:top>
                                    <v-toolbar
                                    flat
                                    >
                                    <!-- Qualification -->
                                    <!-- <v-toolbar-title>Qualification</v-toolbar-title> -->
                                    <v-spacer></v-spacer>
                                    <v-dialog
                                        v-model="dialogContract"
                                        max-width="600"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                dark
                                                class="mb-2"
                                                v-bind="attrs"
                                                v-on="on"
                                                v-if="mode.view == 'admin'"
                                            >
                                            <v-icon>mdi-plus</v-icon>
                                            New Contract
                                            </v-btn>
                                        </template>
                                        <v-card>
                                        <v-card-title class="justify-center title-bar-modul">
                                            <span class="text-h5">Contract Form</span>
                                        </v-card-title>

                                        <v-divider></v-divider>
                            
                                        <v-card-text>
                                            <v-container>
                                            <v-row>
                                                <v-col
                                                cols="12"
                                                sm="6"
                                                >
                                                    <v-menu
                                                    v-if="mode.view == 'admin'"
                                                    v-model="modalstartcontract"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="editedItemContract.startdatecontract"
                                                        label="Date of Contract Start"
                                                        prepend-icon="mdi-calendar"
                                                        outlined
                                                        dense
                                                        hide-details="auto"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :readonly="mode.view == 'user'"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                    v-model="editedItemContract.startdatecontract"
                                                    @input="modalstartcontract = false"
                                                    ></v-date-picker>
                                                </v-menu>
                                                <v-text-field
                                                    v-else
                                                    v-model="editedItemContract.startdatecontract"
                                                    label="Date of Contract Start"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    :readonly="mode.view == 'user'"
                                                ></v-text-field>
                                                </v-col>

                                                <v-col
                                                cols="12"
                                                sm="6"
                                                >
                                                <v-menu
                                                v-if="mode.view == 'admin'"
                                                v-model="modalendcontract"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="editedItemContract.enddatecontract"
                                                        label="Date of Contract End"
                                                        prepend-icon="mdi-calendar"
                                                        outlined
                                                        dense
                                                        hide-details="auto"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :readonly="mode.view == 'user'"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                    v-model="editedItemContract.enddatecontract"
                                                    @input="modalendcontract = false"
                                                    ></v-date-picker>
                                                </v-menu>

                                                <v-text-field
                                                    v-else
                                                    v-model="editedItemContract.enddatecontract"
                                                    label="Date of Contract End"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    :readonly="mode.view == 'user'"
                                                ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-divider></v-divider>
                            
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeContract"
                                            >
                                            Cancel
                                            </v-btn>
                                            <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveContract"
                                            >
                                            Save
                                            </v-btn>
                                        </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog v-model="dialogDeleteContract" max-width="500px">
                                        <v-card>
                                            <v-card-title class="justify-center title-bar-modul">
                                                <h4>Delete Contract Info</h4>
                                            </v-card-title>
                                            <v-simple-table>
                                                <tbody>
                                                    <tr>
                                                        <td class="font-weight-bold" width="200px">Date of Contract Start :</td>
                                                        <td>{{ editedItemContract.startdatecontract }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold" width="200px">Date of Contract End :</td>
                                                        <td>{{ editedItemContract.enddatecontract }}</td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn 
                                                    color="primary" 
                                                    outlined 
                                                    @click="closeDeleteContract"
                                                    > Cancel
                                                </v-btn>
                                                <v-btn 
                                                    color="red" 
                                                    outlined 
                                                    @click="deleteItemConfirmContract"
                                                    > Delete
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                    </v-toolbar>
                                </template>

                                <template v-slot:[`item.actions`]="{ item }" v-if="mode.view == 'admin'">
                                    <v-icon
                                    small
                                    class="mr-2 green--text"
                                    @click="editItemContract(item)"
                                    >
                                    mdi-pencil
                                    </v-icon>
                                    <v-icon
                                    small
                                    class="red--text"
                                    @click="deleteItemContract(item)"
                                    >
                                    mdi-delete
                                    </v-icon>
                                </template>


                                </v-data-table>
                            </v-container>
                        </template>
                    </v-row>
                </v-card-text>
                    
            </v-container>

            <!-- Snackbar for update message -->
            <template>
              <div class="text-center">
                  <v-snackbar
                  v-model="snackbar"
                  :timeout="timeout"
                  :color="messagecolor"
                  >
                  {{ messagetext }}

                  <template v-slot:action="{ attrs }">
                      <v-btn
                      color="white"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                      >
                      Close
                      </v-btn>
                  </template>
                  </v-snackbar>
              </div>
            </template>

    </v-card>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    props: ['mode','employeeInfo', 'contract'],
    
    data: () => ({
        dob: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modaldob: false,
        modaleob: false,
        modalstartcontract: false,
        modalendcontract: false,
        personalGrade: [],
        position: [],
        systemRole: [],
        company: [],
        typeemployment: [],
        stafflist: [],
        
        // employeeInfo: {
        //     dateofemployment:'',
        //     corporategrade:'',
        //     businessgrade:'',
        //     systemrole:'',
        //     employmenttype:'',
        //     cardaccessnumber:'',
        //     superiorname:'',
        //     supervisorname:'',
        //     totalannualleave:'',
        //     annualleaveused:'',
        //     annualleaveadjustment:'',
        //     totalmedicalleave:'',
        //     medicalleaveused:'',
        //     endofemployment:'',

        // },

        //qualification
        dialogContract: false,
        dialogDeleteContract: false,
        headersContract: [
        {
            text: 'Start Date',
            align: 'start',
            sortable: false,
            value: 'startdatecontract',
        },
        { text: 'End Date', value: 'enddatecontract' },
        { text: 'Actions', value: 'actions', sortable: false },
        ],
        // qualify: [],
        editedIndexContract: -1,
        editedItemContract: {
            id: '',
            empid: '', 
            startdatecontract: '',
            enddatecontract:  ''
        },
        defaultItemContract: {
            id: '',
            empid: '', 
            startdatecontract: '',
            enddatecontract:  ''
        },


        // Snackbar message after update data
        snackbar: false,
        timeout: 3000,
        messagecolor: '',
        messagetext: '',

        //   form validation
        valid: true,
    }),

    methods: {
        getEmployeeInfo(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=3&id='+this.$store.getters.user.idEmp, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.employeeInfo = response.data.info;
                // console.log(this.employeeInfo);
            })
            .catch(error => {
                console.log(error);
            })
        },

        getDropdown(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters.token,
                    }
                })
                .then((response) => {
                    this.personalGrade = response.data.personalgrade;
                    // this.position = response.data.position;
                    this.systemRole = response.data.useraccess;
                    this.company = response.data.company;
                    this.typeemployment = response.data.employmenttype;
                })
                .catch(error => {
                    console.log(error);
                })

        },

        getAllStaff(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.stafflist = response.data;
            })
            .catch(error => {
                console.log(error);
            })
        },

        updateEmployeeInfo(){

            if(this.$refs.employeeInfoForm.validate() == true){ 
                let changedId = this.mode.idEmp == this.employeeInfo.staffnum ? null : this.employeeInfo.staffnum

                const payload = {
                    id: this.mode.idEmp,
                    newid : changedId,
                    dateemployment: this.employeeInfo.dateofemployment == null ? '' : this.employeeInfo.dateofemployment.split('T')[0] ,
                    endemployment: this.employeeInfo.endofemployment,
                    // startcontractdt: this.employeeInfo.startcontractdt,
                    // endcontractdt: this.employeeInfo.endcontractdt,
                    idpersonalgrade: this.employeeInfo.personalgradeid,
                    position: this.employeeInfo.position,
                    idcompany: this.employeeInfo.companyid,
                    iduseraccess: this.employeeInfo.systemroleid,
                    idemploymenttype: this.employeeInfo.employtypeid,
                    cardaccessnumber: this.employeeInfo.cardnum,
                    idsuperior: this.employeeInfo.superiorid,
                    idsupevisor: this.employeeInfo.supervisorid,
                    totalannualleave:  this.employeeInfo.totalannualleave,
                    annualleaveused:  this.employeeInfo.annualleaveused,
                    annualleaveadjustment:  this.employeeInfo.annualleaveadjustment,
                    totalmedicalleave:  this.employeeInfo.totalmedicalleave,
                    medicalleaveused:  this.employeeInfo.medicalleaveused,

                };
                // console.log(payload);
                axios.put(process.env.VUE_APP_API_URL + 'employees/edit3', payload, {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters.token,
                    }
                })
                .then((response) => {
                    // console.log(response);
                    this.$emit('updatedData','Yes');
                    this.mode.idEmp != this.employeeInfo.staffnum ? bus.$emit('changeIt', 'changeId') : bus.$emit('changeIt', 'loadProfile');
                    this.snackbar = true;
                    this.messagetext = response.data.message != 'employee info in tab3 edited' ? response.data.message : 'Employee Info successfully updated!';
                    this.messagecolor = 'success';
                })
                .catch(error => {
                    this.snackbar = true;
                    this.messagetext = error.response.data.message;
                    this.messagecolor = 'error';
                })
            }

          

      },
      
      //contract
      initializeContract () {
        this.employeeInfo.kontraklist = []
      }, 
      
      editItemContract (item) {
        this.editedIndexContract = this.employeeInfo.kontraklist.indexOf(item)
        this.editedItemContract = Object.assign({}, item)
        this.dialogContract = true
      },

      deleteItemContract (item) {
        this.editedIndexContract = this.employeeInfo.kontraklist.indexOf(item)
        this.editedItemContract = Object.assign({}, item)
        this.dialogDeleteContract = true
      },

      deleteItemConfirmContract () {

        axios.get(process.env.VUE_APP_API_URL + 'employees/delkontrak?id='+ this.editedItemContract.id , {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
            })
            .then((response) => {
                console.log(response.data);
                this.snackbar = true;
                this.messagetext = 'Contract Deleted!';
                this.messagecolor = 'success';
                this.closeDeleteContract();
                bus.$emit('changeIt', 'loadProfile');
            })
            .catch(error => {
                this.snackbar = true;
                this.messagetext = error.response.data.message;
                this.messagecolor = 'error';
        })

      },

      closeContract () {
        this.dialogContract = false
        this.$nextTick(() => {
          this.editedItemContract = Object.assign({}, this.defaultItemContract)
          this.editedIndexContract = -1
        })
      },

      closeDeleteContract () {
        this.dialogDeleteContract = false
        this.$nextTick(() => {
          this.editedItemContract = Object.assign({}, this.defaultItemContract)
          this.editedIndexContract = -1
        })
      },

      saveContract () {
        if (this.editedIndexContract > -1) {
            const payload = {
                id: this.editedItemContract.id,
                empid: this.editedItemContract.empid, 
                startdatecontract: this.editedItemContract.startdatecontract,
                enddatecontract:  this.editedItemContract.enddatecontract
            }

            axios.put(process.env.VUE_APP_API_URL + 'employees/editkontrak', payload , {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
                })
                .then((response) => {
                    // console.log(response.data);
                    this.snackbar = true;
                    this.messagetext = response.data.message;
                    this.messagecolor = 'success';
                    this.closeContract();
                    bus.$emit('changeIt', 'loadProfile');
                })
                .catch(error => {
                    this.snackbar = true;
                    this.messagetext = error.response.data.message;
                    this.messagecolor = 'error';
            });

        } else {
            const payload = {
                empid: this.mode.idEmp, 
                startdatecontract: this.editedItemContract.startdatecontract,
                enddatecontract:  this.editedItemContract.enddatecontract
            }

            axios.post(process.env.VUE_APP_API_URL + 'employees/addkontrak', payload , {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
                })
                .then((response) => {
                    console.log(response.data);
                    this.snackbar = true;
                    this.messagetext = 'New Contract Added!';
                    this.messagecolor = 'success';
                    this.closeContract();
                    bus.$emit('changeIt', 'loadProfile');
                })
                .catch(error => {
                    this.snackbar = true;
                    this.messagetext = error.response.data.message;
                    this.messagecolor = 'error';
            });
        }
        this.closeContract()
      },

    },

    mounted(){
        // this.getEmployeeInfo();
        this.getAllStaff();
        this.getDropdown();

    }
}
</script>