<template>
    <v-card class="container-modul">
        <v-card-title class="pa-0" ><v-icon class="mr-2" color="blackIcon">mdi-card-account-phone-outline</v-icon>Emergency Contact</v-card-title>
            <v-form
                ref="emergencyContactForm"
                v-model="valid"
                lazy-validation
            >
                <v-container>
                    <v-card-subtitle class="pa-0" >
                        <v-icon color="dark-grey" class="mr-1">mdi-phone-outline</v-icon> Emergency Contact 1
                    </v-card-subtitle>

                    <v-card-text class="mt-5">
                        <v-row>
                            <v-col
                            cols="12"
                            sm="6"
                            >
                            <v-text-field
                                v-model="emergencyInfo[0].ecname"
                                label="Name"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter name']"
                                maxlength='100'
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="6"
                            >
                            <v-text-field
                                v-model="emergencyInfo[0].address"
                                label="Address"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter address']"
                                maxlength='200'
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[0].postalcode"
                                label="Postal Code"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter postal code']"
                                maxlength='5'
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[0].city"
                                label="City"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter city']"
                                maxlength='50'
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-select
                            v-model="emergencyInfo[0].idstate"
                            :items="state"
                            item-text="name"
                            item-value="idstate"
                            label="State"
                            dense
                            outlined
                            hide-details="auto"
                            :rules="[v => !!v || 'Please select state']"
                            ></v-select>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[0].phone"
                                label="Phone No (H/P)"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter phone no']"
                                maxlength='20'
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[0].homephone"
                                label="Phone No (Home)"
                                outlined
                                dense
                                hide-details="auto"
                                maxlength='10'
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[0].officephone"
                                label="Phone No (Office)"
                                outlined
                                dense
                                hide-details="auto"
                                maxlength='10'
                            ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[0].relationship"
                                label="Relationship"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter relationship']"
                                maxlength='50'
                            ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    

                </v-container>

                <v-divider></v-divider>
                
                <v-container>
                    <v-card-subtitle class="pa-0" >
                        <v-icon color="dark-grey" class="mr-1">mdi-phone-outline</v-icon> Emergency Contact 2
                    </v-card-subtitle>

                    <v-card-text class="mt-5">
                        <v-row>
                            <v-col
                            cols="12"
                            sm="6"
                            >
                            <v-text-field
                                v-model="emergencyInfo[1].ecname"
                                label="Name"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter name']"
                                maxlength='100'
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="6"
                            >
                            <v-text-field
                                v-model="emergencyInfo[1].address"
                                label="Address"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter address']"
                                maxlength='200'
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[1].postalcode"
                                label="Postal Code"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter postal code']"
                                maxlength='5'
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[1].city"
                                label="City"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter city']"
                                maxlength='50'
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-select
                            v-model="emergencyInfo[1].idstate"
                            :items="state"
                            item-text="name"
                            item-value="idstate"
                            label="State"
                            dense
                            outlined
                            hide-details="auto"
                            :rules="[v => !!v || 'Please select state']"
                            ></v-select>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[1].phone"
                                label="Phone No (H/P)"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter phone no']"
                                maxlength='20'
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[1].homephone"
                                label="Phone No (Home)"
                                outlined
                                dense
                                hide-details="auto"
                                maxlength='10'
                            ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[1].officephone"
                                label="Phone No (Office)"
                                outlined
                                dense
                                hide-details="auto"
                                maxlength='10'
                            ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                            cols="12"
                            sm="4"
                            >
                            <v-text-field
                                v-model="emergencyInfo[1].relationship"
                                label="Relationship"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[v => !!v || 'Please enter relationship']"
                                maxlength='50'
                            ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-btn
                                class="mr-4"
                                color="primary"
                                @click="emergency == 'new' ? addEmegencyContactInfo() : editEmegencyContactInfo()"
                                v-html="emergency == 'new' ? 'Add' : 'Update'"
                                >
                                </v-btn>
                                <!-- <v-btn
                                color="error"
                                disabled
                                >
                                Reset
                                </v-btn> -->
                            </v-col>
                            
                        </v-row>
                    </v-card-text>
                        
                </v-container>
            </v-form>

            <!-- Snackbar for update message -->
            <template>
              <div class="text-center">
                  <v-snackbar
                  v-model="snackbar"
                  :timeout="timeout"
                  :color="messagecolor"
                  >
                  {{ messagetext }}

                  <template v-slot:action="{ attrs }">
                      <v-btn
                      color="white"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                      >
                      Close
                      </v-btn>
                  </template>
                  </v-snackbar>
              </div>
            </template>

    </v-card>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    props: ['mode','emergencyInfo','emergency'],

    data: () => ({
        state: [],

        // emergencyInfo: [
            // {
            // idemp: '',
            // idstate:'',
            // ecname: '',
            // address: '',
            // postalcode: '',
            // city: '',
            // homephone:'',
            // officephone: '',
            // phone: '',
            // relationship: ''
            // },
        //     {
        //     idemp: '',
        //     idstate:'',
        //     ecname: '',
        //     address: '',
        //     postalcode: '',
        //     city: '',
        //     homephone:'',
        //     officephone: '',
        //     phone: '',
        //     relationship: ''
        //     }
        // ],

        // Snackbar message after update data
        snackbar: false,
        timeout: 3000,
        messagecolor: '',
        messagetext: '',

        //   form validation
        valid: true,

    }),

    methods: {
        getEmergencyInfo(){
            // console.log(this.topic);
            let value; 
            
            this.mode.view == 'user' ? value = this.$store.getters.user.idEmp : value = this.mode.idEmp;

            axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=5&id=' + value, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.emergencyInfo = response.data.emergencycontacts;
            })
            .catch(error => {
                console.log(error);
            })
        },

        getDropdown(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters.token,
                    }
                })
                .then((response) => {
                    this.state = response.data.states;
                })
                .catch(error => {
                    console.log(error);
                })

        },
        addEmegencyContactInfo(){

            if(this.$refs.emergencyContactForm.validate() == true){
                if(this.emergencyInfo[0].phone == this.emergencyInfo[1].phone) {
                    this.snackbar = true;
                    this.messagetext = 'The phone number for emergency contact 1 cannot be same with emergency contact 2!';
                    this.messagecolor = 'error';
                } else {
                    for (let i = 0; i < 2; i++) {
                        const payload = {
                            idemp: this.mode.idEmp,
                            idstate: this.emergencyInfo[i].idstate,
                            ecname: this.emergencyInfo[i].ecname,
                            address: this.emergencyInfo[i].address,
                            postalcode: this.emergencyInfo[i].postalcode,
                            city: this.emergencyInfo[i].city,
                            homephone: this.emergencyInfo[i].homephone,
                            officephone: this.emergencyInfo[i].officephone,
                            phone: this.emergencyInfo[i].phone,
                            relationship: this.emergencyInfo[i].relationship
                        };

                        // console.log(payload);

                        axios.post(process.env.VUE_APP_API_URL + 'employees/addemergencycontact', payload, {
                            headers: {
                                'Authorization': 'Bearer ' + this.$store.getters.token,
                            }
                        })
                        .then((response) => {
                            console.log(response);
                            if(i == 1) {
                                this.snackbar = true;
                                this.messagetext = 'Emergency Contact Info successfully added!';
                                this.messagecolor = 'success';
                                bus.$emit('changeIt', 'loadProfile');
                                this.$emit('updatedData','Yes');
                            }
                        })
                        .catch(error => {
                            this.snackbar = true;
                            this.messagetext = error.response.data.message;
                            this.messagecolor = 'error';
                            // console.log(error);
                        })
                    }
                }
            } else {
                this.snackbar = true;
                this.messagetext = 'Please enter the required field!';
                this.messagecolor = 'error';
            }

        },
        editEmegencyContactInfo(){
            if(this.$refs.emergencyContactForm.validate() == true){
                for (let i = 0; i < 2; i++) {
                    const payload = {
                        id: this.emergencyInfo[i].id,
                        idemp: this.mode.idEmp,
                        idstate: this.emergencyInfo[i].idstate,
                        ecname: this.emergencyInfo[i].ecname,
                        address: this.emergencyInfo[i].address,
                        postalcode: this.emergencyInfo[i].postalcode,
                        city: this.emergencyInfo[i].city,
                        homephone: this.emergencyInfo[i].homephone,
                        officephone: this.emergencyInfo[i].officephone,
                        phone: this.emergencyInfo[i].phone,
                        relationship: this.emergencyInfo[i].relationship
                    };

                    axios.put(process.env.VUE_APP_API_URL + 'employees/editemergencycontact', payload, {
                        headers: {
                            'Authorization': 'Bearer ' + this.$store.getters.token,
                        }
                    })
                    .then((response) => {
                        console.log(response.data);
                        this.snackbar = true;
                        this.messagetext = 'Emergency Contact Info successfully updated!';
                        this.messagecolor = 'success';
                        bus.$emit('changeIt', 'loadProfile');
                        this.$emit('updatedData','Yes');
                    })
                    .catch(error => {
                        this.snackbar = true;
                        this.messagetext = error.response.data.message;
                        this.messagecolor = 'error';
                    })
                }
            } else {
                this.snackbar = true;
                this.messagetext = 'Please enter the required field!';
                this.messagecolor = 'error';
            }

        },
    },

    mounted(){
    //   this.getEmergencyInfo();
      this.getDropdown();
    }
}
</script>