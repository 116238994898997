<template>
    <v-card class="container-modul">
      <v-card-title class="pa-0" ><v-icon class="mr-2" color="blackIcon">mdi-account-details</v-icon>Personal Info</v-card-title>
      <v-form 
        ref="addStaffForm"
        v-model="valid"
        lazy-validation
        >
        <v-container>
          <v-row>
              <v-col
              cols="12"
              sm="6"
              >
              <v-text-field
                  v-model="newStaff.name"
                  label="Employee Name"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[v => !!v || 'Please enter employee name']"
              ></v-text-field>
              </v-col>

              <v-col
              cols="12"
              sm="6"
              >
              <v-text-field
                  v-model="newStaff.noIc"
                  label="IC Number"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[v => !!v || 'Please enter IC number']"
              ></v-text-field>
              </v-col>
          </v-row>

          <v-row>
              <v-col
              cols="12"
              sm="6"
              >
              <v-text-field
                  v-model="newStaff.phone"
                  label="Phone No (H/P)"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[v => !!v || 'Please enter Phone No (H/P)']"
              ></v-text-field>
              </v-col>

              <v-col
              cols="12"
              sm="6"
              >
              <v-text-field
                  v-model="newStaff.homePhone"
                  label="Phone No (Home)"
                  outlined
                  dense
                  hide-details="auto"
              ></v-text-field>
              </v-col>
          </v-row>

          <v-row>
              <v-col
              cols="12"
              sm="4"
              >
              <v-text-field
                  v-model="newStaff.placeBirth"
                  label="Place Of Birth"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[v => !!v || 'Please enter place of birth']"
              ></v-text-field>
              </v-col>

              <v-col
              cols="12"
              sm="4"
              >
              <v-menu
              v-model="modaldob"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              >
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="newStaff.dateBirth"
                      label="Date Of Birth"
                      prepend-icon="mdi-calendar"
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                      :rules="[v => !!v || 'Please choose date of birth']"
                  ></v-text-field>
                  </template>
                  <v-date-picker
                  v-model="newStaff.dateBirth"
                  @input="modaldob = false"
                  ></v-date-picker>
              </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                    v-model="newStaff.gender"
                    :items="gender"
                    item-text="name"
                    item-value="value"
                    label="Gender"
                    outlined
                    dense
                    hide-details="auto"
                    :rules="[v => !!v || 'Please choose gender']"
                ></v-select>
              </v-col>
          </v-row>

          <v-row>
              <v-col
              cols="12"
              sm="6"
              >
              <v-text-field
                  v-model="newStaff.address"
                  label="Address"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[v => !!v || 'Please enter address']"
              ></v-text-field>
              </v-col>

              <v-col
              cols="12"
              sm="6"
              >
              <v-text-field
                  v-model="newStaff.postalCode"
                  label="Postal Code"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[v => !!v || 'Please enter postal code']"
              ></v-text-field>
              </v-col>
          </v-row>

          <v-row>
              <v-col
              cols="12"
              sm="6"
              >
              <v-text-field
                  v-model="newStaff.city"
                  label="City"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[v => !!v || 'Please enter city']"
              ></v-text-field>
              </v-col>
              
              <v-col
              class="d-flex"
              cols="12"
              sm="6"
              >
              <v-select
              v-model="newStaff.idState"
              :items="state"
              item-text="name"
              item-value="idstate"
              label="State"
              dense
              outlined
              hide-details="auto"
              :rules="[v => !!v || 'Please choose state name']"
              ></v-select>
              </v-col>
          </v-row>

          <v-row>
              <v-col
              cols="12"
              sm="6"
              >
              <v-text-field
                  v-model="newStaff.idEmp"
                  label="Staff ID"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[v => !!v || 'Please enter staff ID']"
              ></v-text-field>
              </v-col>

              <v-col
              cols="12"
              sm="6"
              >
              <v-text-field
                  v-model="newStaff.password"
                  label="Password"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[v => !!v || 'Please enter password']"
              ></v-text-field>
              </v-col>

          </v-row>

          <v-row>
              <v-col
              cols="12"
              sm="6"
              >
              <v-select
                  v-model="newStaff.idUserAccess"
                  :items="systemRole"
                  label="System Role"
                  item-text="name"
                  item-value="iduseraccess"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[v => !!v || 'Please choose system role']"
              ></v-select>
              </v-col>

              <v-col
              class="d-flex"
              cols="12"
              sm="6"
              >
              <v-select
              v-model="newStaff.maritalStatus"
              :items="maritalStatus"
              label="Marital Status"
              dense
              outlined
              hide-details="auto"
              :rules="[v => !!v || 'Please choose martial status']"
              ></v-select>
              </v-col>
          </v-row>

          <v-row>
            <v-col
            cols="12"
            sm="6"
            >
            <v-text-field
                v-model="newStaff.email1"
                label="Office Email"
                outlined
                dense
                hide-details="auto"
            ></v-text-field>
            </v-col>

            <v-col
            cols="12"
            sm="6"
            >
            <v-text-field
                v-model="newStaff.email2"
                label="Other Email"
                outlined
                dense
                hide-details="auto"
                :rules="[v => !!v || 'Please enter other email']"
            ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-btn
                class="mr-4"
                color="primary"
                @click="addNewStaff()"
                >
                Add
                </v-btn>
                <v-btn
                color="error"
                >
                Reset
                </v-btn>
              </v-col>
          </v-row>
          
          <template>
              <div class="text-center">
                  <v-snackbar
                  v-model="snackbar"
                  :timeout="timeout"
                  :color="messagecolor"
                  >
                  {{ messagetext }}
                  <template v-slot:action="{ attrs }">
                      <v-btn
                      color="white"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                      >
                      Close
                      </v-btn>
                  </template>
                  </v-snackbar>
              </div>
          </template>

        </v-container>
      </v-form>
    </v-card>
</template>

<script>
import axios from 'axios';

export default {
    data: () => ({
        // dob: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        modaldob: false,
        systemRole: [],
        company: ['MNJ GROUP SDN BHD','SPATIALWORKS SDN BHD','SCIENOTECH SDN BHD','SCIENTIFIC ENVIRONMENT SDN BHD','SERASI INTEGRATED SOLUTIONS SDN BHD','SPATIAL PLANNING CONSULTANTS SDN BHD'],
        typeemployement: [],
        maritalStatus: ['Married', 'Single', 'Divorced', 'Widowed'],
        state: [],
        gender: [
            {
                name: 'Male',
                value: 'M'
            },
            {
                name: 'Female',
                value: 'F'
            }
        ],

        newStaff: {
            idEmp: '',
            idState: '',
            idUserAccess: '',
            name: '',
            noIc: '',
            password: '',
            address: '',
            postalCode: '',
            city: '',
            phone:  '',
            homePhone: '',
            email1: '',
            email2: '',
            gender: '',
            dateBirth: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            placeBirth: '',
            maritalStatus: ''
        },

        snackbar:false,
        messagecolor:'',
        messagetext:'',
        timeout:3000,

        valid: true,
    
    }),

    methods: {
      addNewStaff(){

          if(this.$refs.addStaffForm.validate() == true){

            const payload ={
                idEmp: this.newStaff.idEmp,
                idState: this.newStaff.idState,
                idUserAccess: this.newStaff.idUserAccess,
                name: this.newStaff.name,
                noIc: this.newStaff.noIc,
                password: this.newStaff.password,
                address: this.newStaff.address,
                postalCode: this.newStaff.postalCode,
                city: this.newStaff.city,
                phone: this.newStaff.phone,
                homePhone: this.newStaff.homePhone,
                email1: this.newStaff.email1,
                email2: this.newStaff.email2,
                gender: this.newStaff.gender,
                dateBirth: this.newStaff.dateBirth,
                placeBirth: this.newStaff.placeBirth,
                maritalStatus: this.newStaff.maritalStatus
            };
            // console.log(payload);

            axios.post(process.env.VUE_APP_API_URL + 'employees/add', payload, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                if(response.data.message == 'User added'){
                    this.messagetext = 'New Staff is successfully added!'
                    this.messagecolor = 'success'
                    this.snackbar = true;
                    this.$refs.addStaffForm.reset();
                } else {
                    // console.log(response.data);
                }

            })
            .catch(error => {
                this.messagetext = error.response.data.message
                this.messagecolor = 'error'
                this.snackbar = true;
            })
          }
      },

      getState(){
        axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
                  headers: {
                      'Authorization': 'Bearer ' + this.$store.getters.token,
                  }
              })
              .then((response) => {
                  this.state = response.data.states;
                  this.systemRole = response.data.useraccess;
                //   console.log(this.state);
              })
              .catch(error => {
                  console.log(error);
              })

      },

    },

    mounted(){
      this.getState();
      
    }
}

</script>