<template>
    <v-container>

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-account-group</v-icon>
            Admin - Staff List
        </v-card-title>

        <!-- Tab List-->
        <v-tabs
          v-model="tabs"
          show-arrows="mobile"
        >

          <v-tab
            v-for="item in tabsStaffList"
            :key="item.tab"
            class="tab-icon"
          >
            <v-icon>{{ item.icon }}</v-icon>
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
            
            <!-- tab dashboard-->
            <v-tab-item  class="container-tab-modul">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                        <!-- <v-card
                            min-height="150"
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5 mb-1">
                                    ALL MNJG
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-icon x-large>mdi-calendar-blank</v-icon>
                            </v-list-item>

                            <v-container>
                              <v-row>
                                  <v-col cols="12" sm="6" >
                                      <v-card-text class="pa-0">
                                          <span class="font-weight-bold">Active Staff:</span>
                                          {{ allMnjActive }}
                                      </v-card-text>
                                  </v-col>
                                  <v-col cols="12" sm="6" >
                                      <v-card-text class="pa-0">
                                          <span class="font-weight-bold">Inactive Staff:</span>
                                          0
                                      </v-card-text>
                                  </v-col>
                                  <v-avatar
                                        class="ma-3"
                                        size="125"
                                        tile
                                    >
                                        <v-img src="@/assets/img/others/MNJ.png"></v-img>
                                    </v-avatar> 
                              </v-row>
                            </v-container>
                        </v-card> -->

                        <v-card min-height="150" width="100%">
                            <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <v-card-title
                                class="text-h5"
                                >ALL MNJG</v-card-title>

                                <!-- <v-card-subtitle v-text="item.artist"></v-card-subtitle> -->

                                <v-col cols="12" sm="12">
                                    <v-card-text class="pa-0">
                                        <span class="font-weight-bold">Active Staff:</span>
                                        {{ allMnjActive }}
                                    </v-card-text>
                                    <v-card-text class="pa-0">
                                        <span class="font-weight-bold">Inactive Staff:</span>
                                        {{ allMnjInactive }}
                                    </v-card-text>
                                </v-col>
                            </div>

                            <v-avatar
                                class="ma-3 pa-3"
                                :size="($vuetify.breakpoint.smAndDown) ? '90' : '115'"
                                tile
                            >
                                <v-img src="@/assets/img/others/MNJ-trans.png"></v-img>
                            </v-avatar>
                            </div>
                        </v-card>


                        </v-col>
                        <v-col cols="12" md="6">
                        <!-- <v-card
                            min-height="150"
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5 mb-1">
                                    MNJG
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-container>
                              <v-row>
                                  <v-col cols="12" sm="6" >
                                      <v-card-text class="pa-0">
                                          <span class="font-weight-bold">Active Staff:</span>
                                          {{ mnjActive }}
                                      </v-card-text>
                                  </v-col>
                                  <v-col cols="12" sm="6" >
                                      <v-card-text class="pa-0">
                                          <span class="font-weight-bold">Inactive Staff:</span>
                                          0
                                      </v-card-text>
                                  </v-col>
                              </v-row>
                            </v-container>
                        </v-card> -->
                        <v-card min-height="150" width="100%">
                            <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <v-card-title
                                class="text-h5"
                                >MNJG</v-card-title>

                                <v-col cols="12" sm="12">
                                    <v-card-text class="pa-0">
                                        <span class="font-weight-bold">Active Staff:</span>
                                        {{ mnjActive }}
                                    </v-card-text>
                                    <v-card-text class="pa-0">
                                        <span class="font-weight-bold">Inactive Staff:</span>
                                        {{ mnjInactive }}
                                    </v-card-text>
                                </v-col>
                            </div>

                            <v-avatar
                                class="ma-3 pa-3"
                                :size="($vuetify.breakpoint.smAndDown) ? '90' : '115'"
                                tile
                            >
                                <v-img src="@/assets/img/others/MNJ-trans.png"></v-img>
                            </v-avatar>
                            </div>
                        </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <!-- <v-card
                                min-height="150"
                            >
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5 mb-1">
                                        SPATIALWORKS
                                        </v-list-item-title>
                                    </v-list-item-content>

                                </v-list-item>

                                <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" >
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Active Staff:</span>
                                            {{ spwActive }}
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Inactive Staff:</span>
                                            0
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                                </v-container>
                            </v-card> -->

                            <v-card min-height="150" width="100%">
                                <div class="d-flex flex-no-wrap justify-space-between">
                                <div>
                                    <v-card-title
                                    class="text-h5"
                                    >SPATIALWORKS</v-card-title>

                                    <v-col cols="12" sm="12">
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Active Staff:</span>
                                            {{ spwActive }}
                                        </v-card-text>
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Inactive Staff:</span>
                                            {{ spwInactive }}
                                        </v-card-text>
                                    </v-col>
                                </div>

                                <v-avatar
                                    class="ma-3 pa-3"
                                    width="auto"
                                    height="auto"
                                    tile
                                >
                                    <v-img src="@/assets/img/others/logo-spatialworks.png"></v-img>
                                </v-avatar>
                                </div>
                            </v-card>
                            
                        </v-col>

                        <v-col cols="12" md="6">
                            <!-- <v-card
                                min-height="150"
                            >
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5 mb-1">
                                        SCIENOTECH
                                        </v-list-item-title>
                                    </v-list-item-content>

                                </v-list-item>

                                <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" >
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Active Staff:</span>
                                            {{ sctActive }}
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Inactive Staff:</span>
                                            0
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                                </v-container>
                            </v-card> -->

                            <v-card min-height="150" width="100%">
                                <div class="d-flex flex-no-wrap justify-space-between">
                                <div>
                                    <v-card-title
                                    class="text-h5"
                                    >SCIENOTECH</v-card-title>

                                    <v-col cols="12" sm="12">
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Active Staff:</span>
                                            {{ sctActive }}
                                        </v-card-text>
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Inactive Staff:</span>
                                            0
                                        </v-card-text>
                                    </v-col>
                                </div>

                                <!-- <v-avatar
                                    class="ma-3 pa-3"
                                    :size="($vuetify.breakpoint.smAndDown) ? '90' : '115'"
                                    tile
                                > -->
                                <v-avatar
                                    class="ma-3 pa-3"
                                    width="auto"
                                    height="auto"
                                    tile
                                >
                                    <v-img src="@/assets/img/others/logo-scienotech.png"></v-img>
                                </v-avatar>
                                </div>
                            </v-card>

                        </v-col>
                        <v-col cols="12" md="6">
                            <!-- <v-card
                                min-height="150"
                            >
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5 mb-1">
                                        SERASI
                                        </v-list-item-title>
                                    </v-list-item-content>

                                </v-list-item>

                                <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" >
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Active Staff:</span>
                                            {{ sisActive }}
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Inactive Staff:</span>
                                            0
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                                </v-container>
                            </v-card> -->

                            <v-card min-height="150" width="100%">
                                <div class="d-flex flex-no-wrap justify-space-between">
                                <div>
                                    <v-card-title
                                    class="text-h5"
                                    >SERASI</v-card-title>

                                    <v-col cols="12" sm="12">
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Active Staff:</span>
                                            {{ sisActive }}
                                        </v-card-text>
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Inactive Staff:</span>
                                            {{ sisInactive }}
                                        </v-card-text>
                                    </v-col>
                                </div>

                                <v-avatar
                                    class="ma-3 pa-3"
                                    width="auto"
                                    height="auto"
                                    tile
                                >
                                    <v-img src="@/assets/img/others/logo-serasi2.jpeg"></v-img>
                                </v-avatar>
                                </div>
                            </v-card>

                        </v-col>
                        <v-col cols="12" md="6">
                        <!-- <v-card
                            min-height="150"
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5 mb-1">
                                    SENSE
                                    </v-list-item-title>
                                </v-list-item-content>

                            </v-list-item>

                            <v-container>
                              <v-row>
                                  <v-col cols="12" sm="6" >
                                      <v-card-text class="pa-0">
                                          <span class="font-weight-bold">Active Staff:</span>
                                          {{ senseActive }}
                                      </v-card-text>
                                  </v-col>
                                  <v-col cols="12" sm="6" >
                                      <v-card-text class="pa-0">
                                          <span class="font-weight-bold">Inactive Staff:</span>
                                          0
                                      </v-card-text>
                                  </v-col>
                              </v-row>
                            </v-container>
                        </v-card> -->

                        <v-card min-height="150" width="100%">
                            <div class="d-flex flex-no-wrap justify-space-between">
                                <div>
                                    <v-card-title
                                    class="text-h5"
                                    >SENSE</v-card-title>

                                    <v-col cols="12" sm="12">
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Active Staff:</span>
                                            {{ senseActive }}
                                        </v-card-text>
                                        <v-card-text class="pa-0">
                                            <span class="font-weight-bold">Inactive Staff:</span>
                                            {{ senseInactive }}
                                        </v-card-text>
                                    </v-col>
                                </div>

                                <v-avatar
                                    class="ma-3 pa-3"
                                    width="auto"
                                    height="auto"
                                    tile
                                >
                                    <v-img src="@/assets/img/others/logo-sense.png"></v-img>
                                </v-avatar>
                            </div>
                        </v-card>
                        </v-col>
                    </v-row>
                    
                    <!-- <v-row>
                        <v-col cols="12" md="4">
                        <v-card
                            min-height="150"
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5 mb-1">
                                    SPC
                                    </v-list-item-title>
                                </v-list-item-content>

                            </v-list-item>

                            <v-container>
                              <v-row>
                                  <v-col cols="12" sm="6" >
                                      <v-card-text class="pa-0">
                                          <span class="font-weight-bold">Active Staff:</span>
                                          0
                                      </v-card-text>
                                  </v-col>
                                  <v-col cols="12" sm="6" >
                                      <v-card-text class="pa-0">
                                          <span class="font-weight-bold">Inactive Staff:</span>
                                          0
                                      </v-card-text>
                                  </v-col>
                              </v-row>
                            </v-container>
                        </v-card>
                        </v-col>
                    </v-row> -->
                </v-container>
                
            </v-tab-item>

            <!-- tab view list -->
            <v-tab-item class="container-tab-modul">
                <view-list>

                </view-list>
            </v-tab-item>

            <!-- tab update staff -->
            <v-tab-item class="container-tab-modul">
                <add-staff>
                    
                </add-staff>
            </v-tab-item>

        </v-tabs-items>

    </v-container>
</template>

<script>
import axios from 'axios';
import viewList from '@/components/staffList/ViewList.vue';
import addStaff from '@/components/staffList/AddStaff.vue';

export default {
    
    components: {
        'view-list': viewList,
        'add-staff': addStaff,
    },
    data: () => ({

        tabs: null,
        tabsStaffList: [
            {tab:'Dashboard', icon:'mdi-view-dashboard-variant-outline'},
            {tab:'View List', icon:'mdi-view-list'},
            {tab:'Add Staff', icon:'mdi-account-plus'}
        ],
        allMnjActive: null, 
        allMnjInactive: null, 
        spwActive: null, 
        spwInactive: null, 
        sctActive: null, 
        sctInactive: null, 
        mnjActive: null, 
        mnjInactive: null, 
        sisActive: null, 
        sisInactive: null, 
        senseActive: null, 
        senseInactive: null, 
    }),

    methods: {
        getAllStaff(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {

                let count = {
                    all_active: 0,
                    all_inactive: 0,
                    spw_active: 0,
                    spw_inactive: 0,
                    sct_active: 0,
                    sct_inactive: 0,
                    mnj_active: 0,
                    mnj_inactive: 0,
                    sis_active: 0,
                    sis_inactive: 0,
                    sense_active: 0,
                    sense_inactive: 0,

                }

                for(let i = 0; i < response.data.length; i++){
                    if(response.data[i].status == 'ACTIVE'){
                        count.all_active++;
                    } else if (response.data[i].status == 'INACTIVE'){
                        count.all_inactive++;
                    }


                    if(response.data[i].company == 'SPATIALWORKS SDN BHD' && response.data[i].status == 'ACTIVE'){
                        count.spw_active++;
                    } else if (response.data[i].company == 'SPATIALWORKS SDN BHD' && response.data[i].status == 'INACTIVE'){
                        count.spw_inactive++;
                    } else if (response.data[i].company == 'SCIENOTECH SDN BHD' && response.data[i].status == 'ACTIVE'){
                        count.sct_active++;
                    } else if (response.data[i].company == 'SCIENOTECH SDN BHD' && response.data[i].status == 'INACTIVE'){
                        count.sct_inactive++;
                    } else if (response.data[i].company == 'MNJ GROUP SDN BHD' && response.data[i].status == 'ACTIVE'){
                        count.mnj_active++;
                    } else if (response.data[i].company == 'MNJ GROUP SDN BHD' && response.data[i].status == 'INACTIVE'){
                        count.mnj_inactive++;
                    } else if (response.data[i].company == 'SERASI INTEGRATED SOLUTIONS SDN BHD' && response.data[i].status == 'ACTIVE'){
                        count.sis_active++;
                    } else if (response.data[i].company == 'SERASI INTEGRATED SOLUTIONS SDN BHD' && response.data[i].status == 'INACTIVE'){
                        count.sis_inactive++;
                    } else if (response.data[i].company == 'SCIENTIFIC ENVIRONMENT SDN BHD' && response.data[i].status == 'ACTIVE'){
                        count.sense_active++;
                    } else if (response.data[i].company == 'SCIENTIFIC ENVIRONMENT SDN BHD' && response.data[i].status == 'INACTIVE'){
                        count.sense_inactive++;
                    }
                }

                this.allMnjActive = count.all_active;
                this.allMnjInactive = count.all_inactive;

                this.spwActive = count.spw_active;
                this.spwInactive = count.spw_inactive;

                this.sctActive = count.sct_active;
                this.sctInactive = count.sct_inactive;

                this.mnjActive = count.mnj_active;
                this.mnjInactive = count.mnj_inactive;

                this.sisActive = count.sis_active;
                this.sisInactive = count.sis_inactive;

                this.senseActive = count.sense_active;
                this.senseInactive = count.sense_inactive;
            })
            .catch(error => {
                console.log(error);
            })
        },
    },
    
    mounted(){
        this.getAllStaff();
    },
    
}

</script>

<style lang="scss">
@import '~scss/main';

 .container-modul{
    padding: 10px;
    overflow-y: auto;
}

</style>
