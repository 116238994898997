<template>
    <v-card class="container-modul">
        <v-card-title class="pa-0" ><v-icon class="mr-2" color="blackIcon">mdi-briefcase-account-outline</v-icon>HR Info</v-card-title>
            <v-form>
                <v-container>
                <!-- <div class="text-subtitle-2 text-bold"></div> -->
                <v-card-subtitle class="pa-0" >
                    <v-icon color="dark-grey" class="mr-1">mdi-bank-outline</v-icon> Financial Info - For Office Use Only
                </v-card-subtitle>

                <v-card-text>
                    <v-row class="mt-2">
                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="financeInfo.epfno"
                            label="EPF Number"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="financeInfo.socsono"
                            label="SOCSO Number"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="4"
                        >
                        <v-text-field
                            v-model="financeInfo.lhdnno"
                            label="LHDN Number"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-text-field
                            v-model="financeInfo.accbankno"
                            label="Bank Account Number"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <!-- <v-text-field
                            v-model="financeInfo.namebank"
                            label="Bank Name"
                            outlined
                            dense
                            hide-details="auto"
                        ></v-text-field> -->
                        <v-select
                            v-model="financeInfo.namebank"
                            :items="bankInfo"
                            label="Bank Name"
                            outlined
                            dense
                            hide-details="auto"
                            :readonly="mode.view == 'user'"
                        ></v-select>
                        </v-col>
                    </v-row>

                    <v-row v-if="mode.view == 'admin'">
                        <v-col cols="12">
                            <v-btn
                            class="mr-4"
                            color="primary"
                            @click="updateHRInfo()"
                            >
                            Update
                            </v-btn>
                            <!-- <v-btn
                            color="error"
                            disabled
                            >
                            Reset
                            </v-btn> -->
                        </v-col>
                        
                    </v-row>
                </v-card-text>
                    
            </v-container>
        </v-form>
            
        <v-divider></v-divider>

        <v-container>
            <v-card-subtitle class="pa-0" >
                <v-icon color="dark-grey" class="mr-1">mdi-school-outline</v-icon> Educational Info
            </v-card-subtitle>
            <!-- <div class="text-subtitle-2 mb-2">Educational Info</div> -->
            <v-card-text class="mt-2">
                <v-row>
                    <template>
                        <v-container>
                            <v-data-table
                            :headers="headersQualify"
                            :items="qualify"
                            :items-per-page="5"
                            class="elevation-1"
                            >
                            <template v-slot:top>
                                <v-toolbar
                                flat
                                >
                                <!-- Qualification -->
                                <!-- <v-toolbar-title>Qualification</v-toolbar-title> -->
                                <v-spacer></v-spacer>
                                <v-dialog
                                    v-model="dialogQualify"
                                    max-width="600"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            dark
                                            class="mb-2"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <v-icon>mdi-plus</v-icon>
                                        New Qualification
                                        </v-btn>
                                    </template>
                                    <v-card>
                                    <v-card-title class="justify-center title-bar-modul">
                                        <span class="text-h5">Qualification Form</span>
                                    </v-card-title>
                        
                                    <v-card-text>
                                        <v-container>
                                        <v-row>
                                            <v-col
                                            cols="12"
                                            sm="6"
                                            >
                                                <v-select
                                                :items="qualification"
                                                v-model="editedItemQualify.qualification"
                                                label="Qualification"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                ></v-select>
                                            </v-col>

                                            <v-col
                                            cols="12"
                                            sm="6"
                                            >
                                                <v-text-field
                                                v-model="editedItemQualify.course"
                                                label="Course/Major"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col
                                            cols="12"
                                            sm="6"
                                            >
                                                <v-text-field
                                                v-model="editedItemQualify.institute"
                                                label="Institute/University"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                ></v-text-field>
                                            </v-col>

                                            <v-col
                                            cols="12"
                                            sm="6"
                                            >
                                                <v-text-field
                                                v-model="editedItemQualify.graduation"
                                                label="Graduation Year"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col
                                            cols="12"
                                            sm="6"
                                            >
                                            <v-file-input
                                                label="Certification"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                @change="fileUploadOnChange($event,'qualify')"
                                            ></v-file-input>
                                            </v-col>
                                        </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-divider></v-divider>
                        
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeQualify"
                                        >
                                        Cancel
                                        </v-btn>
                                        <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="saveQualify"
                                        >
                                        Save
                                        </v-btn>
                                    </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogDeleteQualify" max-width="500px">
                                    <v-card>
                                        <v-card-title class="justify-center title-bar-modul">
                                            <h4>Delete Educational Info</h4>
                                        </v-card-title>
                                        <v-simple-table>
                                            <tbody>
                                                <tr>
                                                    <td class="font-weight-bold" width="200px">Qualification :</td>
                                                    <td>{{ editedItemQualify.qualification }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold" width="200px">Course/Major :</td>
                                                    <td>{{ editedItemQualify.course }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold" width="200px">Institute/University :</td>
                                                    <td>{{ editedItemQualify.institute }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold" width="200px">Graduation Year :</td>
                                                    <td>{{ editedItemQualify.graduation }}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn 
                                                color="primary" 
                                                outlined 
                                                @click="closeDeleteQualify"
                                                > Cancel
                                            </v-btn>
                                            <v-btn 
                                                color="red" 
                                                outlined 
                                                @click="deleteItemConfirmQualify"
                                                > Delete
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.cert`]="{ item }">
                                <v-icon
                                small
                                class="mr-2"
                                @click="viewCert(item.cert)"
                                v-if="item.cert != null && item.cert != ''"
                                >
                                mdi-file-document-outline
                                </v-icon>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                small
                                class="mr-2 green--text"
                                @click="editItemQualify(item)"
                                >
                                mdi-pencil
                                </v-icon>
                                <v-icon
                                small
                                class="red--text"
                                @click="deleteItemQualify(item)"
                                >
                                mdi-delete
                                </v-icon>
                            </template>
                            </v-data-table>
                        </v-container>
                    </template>
                </v-row>
            </v-card-text>
                
        </v-container>

                

                    
            
        <v-divider></v-divider>

        <v-container>
            <v-card-subtitle class="pa-0" >
                <v-icon color="dark-grey" class="mr-1">mdi-script-text-outline</v-icon> Professional Certification
            </v-card-subtitle>
            <v-card-text class="mt-2">
                <v-row>
                    <template>
                        <v-container>
                            <v-data-table
                            :headers="headersCertificate"
                                :items="certificate"
                                :items-per-page="5"
                                class="elevation-1"
                            >
                            <template v-slot:top>
                                <v-toolbar
                                flat
                                >
                                <!-- Certificate -->

                                <v-spacer></v-spacer>
                                <v-dialog
                                    v-model="dialogCertificate"
                                    max-width="600"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            dark
                                            class="mb-2"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                        <v-icon>mdi-plus</v-icon>
                                        New Certificate
                                        </v-btn>
                                    </template>
                                    <v-card>
                                    <v-card-title class="justify-center title-bar-modul">
                                        <span class="text-h5">Professional Certificate Form</span>
                                    </v-card-title>
                        
                                    <v-card-text>
                                        <v-container>
                                        <v-row>
                                            <v-col
                                            cols="12"
                                            sm="6"
                                            >
                                                <v-text-field
                                                v-model="editedItemCertificate.organisation"
                                                label="Organisation"
                                                dense
                                                outlined
                                                hide-details="auto"
                                                ></v-text-field>
                                            </v-col>

                                            <v-col
                                            cols="12"
                                            sm="6"
                                            >
                                                <v-text-field
                                                v-model="editedItemCertificate.memberno"
                                                label="Members Number"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col
                                            cols="12"
                                            sm="12"
                                            >
                                            <v-file-input
                                                label="Certification"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                @change="fileUploadOnChange($event,'certificate')"
                                            ></v-file-input>
                                            </v-col>
                                        </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-divider></v-divider>
                        
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeCertificate"
                                        >
                                        Cancel
                                        </v-btn>
                                        <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="saveCertificate"
                                        >
                                        Save
                                        </v-btn>
                                    </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogDeleteCertificate" max-width="500px">
                                    <v-card>
                                        <v-card-title class="justify-center title-bar-modul">
                                            <h4>Delete Professional Certification</h4>
                                        </v-card-title>
                                        <v-simple-table>
                                            <tbody>
                                                <tr>
                                                    <td class="font-weight-bold" width="200px">Organisation :</td>
                                                    <td>{{ editedItemCertificate.organisation }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold" width="200px">Members Number :</td>
                                                    <td>{{ editedItemCertificate.memberno }}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn 
                                                color="primary" 
                                                outlined 
                                                @click="closeDeleteCertificate"
                                                > Cancel
                                            </v-btn>
                                            <v-btn 
                                                color="red" 
                                                outlined 
                                                @click="deleteItemConfirmCertificate"
                                                > Delete
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.cert`]="{ item }">
                                <v-icon
                                small
                                class="mr-2"
                                @click="viewCert(item.cert)"
                                v-if="item.cert != null && item.cert != ''"
                                >
                                mdi-file-document-outline
                                </v-icon>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                small
                                class="mr-2 green--text"
                                @click="editItemCertificate(item)"
                                >
                                mdi-pencil
                                </v-icon>
                                <v-icon
                                small
                                class="red--text"
                                @click="deleteItemCertificate(item)"
                                >
                                mdi-delete
                                </v-icon>
                            </template>
                            </v-data-table>
                        </v-container>
                    </template>
                </v-row>
            </v-card-text>
                
        </v-container>

                <!-- <h4>Professional Certification</h4><br>

                    <v-row>
                        <template>
                            <v-container>
                                <v-data-table
                                :headers="headersCertificate"
                                :items="certificate"
                                :items-per-page="5"
                                class="elevation-1"
                                >
                                <template v-slot:top>
                                    <v-toolbar
                                    flat
                                    >
                                    <v-toolbar-title>Certificate</v-toolbar-title>
                                    <v-divider
                                        class="mx-4"
                                        inset
                                        vertical
                                    ></v-divider>
                                    <v-spacer></v-spacer>
                                    <v-dialog
                                        v-model="dialogCertificate"
                                        max-width="100%"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                dark
                                                class="mb-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                            <v-icon>mdi-plus</v-icon>
                                            New Certificate
                                            </v-btn>
                                        </template>
                                        <v-card>
                                        <v-card-title>
                                            <span class="text-h5">{{ formTitleCertificate }}</span>
                                        </v-card-title>
                            
                                        <v-card-text>
                                            <v-container>
                                            <v-row>
                                                <v-col
                                                cols="12"
                                                sm="6"
                                                >
                                                    <v-text-field
                                                    v-model="editedItemCertificate.organisation"
                                                    label="Organisation"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    ></v-text-field>
                                                </v-col>

                                                <v-col
                                                cols="12"
                                                sm="6"
                                                >
                                                    <v-text-field
                                                    v-model="editedItemCertificate.memberno"
                                                    label="Members Number"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            </v-container>
                                        </v-card-text>
                            
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeCertificate"
                                            >
                                            Cancel
                                            </v-btn>
                                            <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveCertificate"
                                            >
                                            Save
                                            </v-btn>
                                        </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog v-model="dialogDeleteCertificate" max-width="500px">
                                        <v-card>
                                        <v-card-title class="text-h5">Are you sure you want to delete?</v-card-title>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="closeDeleteCertificate">Cancel</v-btn>
                                            <v-btn color="blue darken-1" text @click="deleteItemConfirmCertificate">OK</v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                    small
                                    class="mr-2"
                                    @click="editItemCertificate(item)"
                                    >
                                    mdi-pencil
                                    </v-icon>
                                    <v-icon
                                    small
                                    @click="deleteItemCertificate(item)"
                                    >
                                    mdi-delete
                                    </v-icon>
                                </template>
                                <template v-slot:no-data>
                                    <v-btn
                                    color="primary"
                                    @click="initializeCertificate"
                                    >
                                    Reset
                                    </v-btn>
                                </template>
                                </v-data-table>
                            </v-container>
                        </template>
                    </v-row>


                    <v-row>

                        <v-col
                        cols="12"
                        sm="6"
                        >
                        <v-file-input
                            v-model="profcertInfo.cert"
                            label="Certification"
                            outlined
                            dense
                            hide-details="auto"
                        ></v-file-input>
                        </v-col>
                    </v-row> -->

                    <!-- <v-row>
                        <v-btn
                        class="mr-4"
                        color="secondary"
                        >
                        Submit
                        </v-btn>
                        <v-btn
                        color="error"
                        >
                        Reset
                        </v-btn>
                    </v-row> -->

            <!-- Snackbar for update message -->
            <template>
              <div class="text-center">
                  <v-snackbar
                  v-model="snackbar"
                  :timeout="timeout"
                  :color="messagecolor"
                  >
                  {{ messagetext }}

                  <template v-slot:action="{ attrs }">
                      <v-btn
                      color="white"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                      >
                      Close
                      </v-btn>
                  </template>
                  </v-snackbar>
              </div>
            </template>


    </v-card>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {

    props: ['mode','financeInfo','qualify', 'certificate'],

    data: () => ({
        qualification: ['SPM','SKM','Matriculation','Diploma', 'Bachelor degree', 'Postgraduate Certificate & Diploma', 'Masters degree', 'Doctoral Degree'],
        
        // financeInfo: {
        //   epfno:'',
        //   socsono:'',
        //   lhdnno:'',
        //   accbankno:'',
        //   namebank:'',
        // },

        bankInfo: ['Malayan Banking', 'CIMB Bank', 'Public Bank', '	RHB Bank', 'Hong Leong Bank', 'AmBank', 'United Overseas Bank', 'Bank Rakyat', 'OCBC', 'HSBC', 'Bank Islam', 'Affin Bank', 'Alliance Bank', 'Standard Chartered',
                    'MBSB Bank', 'Citibank', 'Bank Simpanan Nasional', 'Bank Muamalat', 'Agrobank', 'Al-Rajhi', 'Co-op Bank'],

        educationInfo:{
          qualification:'',
          course:'',
          institute:'',
          graduation:'',
          cert:'',

        },

        profcertInfo:{
          organisation:'',
          memberno:'',
          cert:'',

        },

        //qualification
        dialogQualify: false,
        dialogDeleteQualify: false,
        headersQualify: [
        {
            text: 'Qualification',
            align: 'start',
            sortable: false,
            value: 'qualification',
        },
        { text: 'Course/Major', value: 'course' },
        { text: 'Institute/University', value: 'institute' },
        { text: 'Graduation Year', value: 'graduation' },
        { text: 'Certificate', value: 'cert' },
        { text: 'Actions', value: 'actions', sortable: false },
        ],
        // qualify: [],
        editedIndexQualify: -1,
        editedItemQualify: {
            id: '',
            idemp: '', 
            qualification: '',
            course:  '',
            institute:  '',
            graduation:  '',
            cert:''
        },
        defaultItemQualify: {
            id: '',
            idemp: '', 
            qualification: '',
            course:  '',
            institute:  '',
            graduation:  '',
            cert:''
        },

        //certificate
        dialogCertificate: false,
        dialogDeleteCertificate: false,
        headersCertificate: [
        {
            text: 'Organisation',
            align: 'start',
            sortable: false,
            value: 'organisation',
        },
        { text: 'Members Number', value: 'memberno' },
        { text: 'Certificate', value: 'cert' },
        { text: 'Actions', value: 'actions', sortable: false },
        ],
        // certificate: [],
        editedIndexCertificate: -1,
        editedItemCertificate: {
            idemp: '',
            organisation: '',
            memberno: '',
            cert: ''
        },
        defaultItemCertificate: {
            idemp: '',
            organisation: '',
            memberno: '',
            cert: ''
        },

        // Snackbar message after update data
        snackbar: false,
        timeout: 3000,
        messagecolor: '',
        messagetext: '',
        
    }),

    // computed: {

    //   formTitleQualify () {
    //     return this.editedIndexQualify === -1 ? 'New Qualification' : 'Edit Qualification'
    //   },

    //   formTitleCertificate () {
    //     return this.editedIndexCertificate === -1 ? 'New Certificate' : 'Edit Certificate'
    //   },
    // },

    // watch: {
    //   dialogQualify (val) {
    //     val || this.closeQualify()
    //   },
    //   dialogDeleteQualify (val) {
    //     val || this.closeDeleteQualify()
    //   },
    //   dialogCertificate (val) {
    //     val || this.closeCertificate()
    //   },
    //   dialogDeleteCertificate (val) {
    //     val || this.closeDeleteCertificate()
    //   },
    // },

    // created () {
    //   this.initializeQualify()
    //   this.initializeCertificate()
    // },

    methods: {
      getHrInfo(){
        axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=4&id='+this.$store.getters.user.idEmp,{
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
        })
            .then((response) => {
                this.financeInfo = response.data.finance;
                this.qualify = response.data.education;
                this.certificate = response.data.professionalcert;
                console.log(this.HrInfo);
            })
            .catch(error => {
                console.log(error);
            })
      },

      //qualify
      initializeQualify () {
        this.qualify = []
      },

      //certificate
      initializeCertificate () {
        this.certificate = []
      },
      
      //qualify  
      editItemQualify (item) {
        this.editedIndexQualify = this.qualify.indexOf(item)
        this.editedItemQualify = Object.assign({}, item)
        this.dialogQualify = true
      },

      deleteItemQualify (item) {
        this.editedIndexQualify = this.qualify.indexOf(item)
        this.editedItemQualify = Object.assign({}, item)
        this.dialogDeleteQualify = true
      },

      deleteItemConfirmQualify () {

        axios.get(process.env.VUE_APP_API_URL + 'employees/deledu?idemp='+ this.editedItemQualify.idemp +'&qualification=' + this.editedItemQualify.qualification, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
            })
            .then((response) => {
                console.log(response.data);
                // this.qualify.splice(this.editedIndexQualify, 1);
                this.closeDeleteQualify();
                bus.$emit('changeIt', 'loadProfile');
            })
            .catch(error => {
                console.log(error);
        })

      },

      closeQualify () {
        this.dialogQualify = false
        this.$nextTick(() => {
          this.editedItemQualify = Object.assign({}, this.defaultItemQualify)
          this.editedIndexQualify = -1
        })
      },

      closeDeleteQualify () {
        this.dialogDeleteQualify = false
        this.$nextTick(() => {
          this.editedItemQualify = Object.assign({}, this.defaultItemQualify)
          this.editedIndexQualify = -1
        })
      },

      saveQualify () {
        if (this.editedIndexQualify > -1) {
            // Object.assign(this.qualify[this.editedIndexQualify], this.editedItemQualify);
            const payload = {
                id: this.editedItemQualify.id,
                idemp: this.editedItemQualify.idemp, 
                qualification: this.editedItemQualify.qualification,
                course:  this.editedItemQualify.course,
                institute:  this.editedItemQualify.institute,
                graduation:  this.editedItemQualify.graduation,
                cert: this.editedItemQualify.cert
            }

            axios.put(process.env.VUE_APP_API_URL + 'employees/editedu', payload , {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
                })
                .then((response) => {
                    console.log(response.data);
                    bus.$emit('changeIt', 'loadProfile');
                })
                .catch(error => {
                    console.log(error);
            });

        } else {
            // this.qualify.push(this.editedItemQualify)
            const payload = {
                idemp: this.mode.idEmp, 
                qualification: this.editedItemQualify.qualification,
                course:  this.editedItemQualify.course,
                institute:  this.editedItemQualify.institute,
                graduation:  this.editedItemQualify.graduation,
                cert: this.editedItemQualify.cert
            }

            axios.post(process.env.VUE_APP_API_URL + 'employees/addedu', payload , {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
                })
                .then((response) => {
                    console.log(response.data);
                    bus.$emit('changeIt', 'loadProfile');
                })
                .catch(error => {
                    console.log(error);
            });
        }
        this.closeQualify()
      },
      
      //certificate  
      editItemCertificate (item) {
        this.editedIndexCertificate = this.certificate.indexOf(item)
        this.editedItemCertificate = Object.assign({}, item)
        this.dialogCertificate = true
      },

      deleteItemCertificate (item) {
        this.editedIndexCertificate = this.certificate.indexOf(item)
        this.editedItemCertificate = Object.assign({}, item)
        this.dialogDeleteCertificate = true
      },

      deleteItemConfirmCertificate () {

        axios.get(process.env.VUE_APP_API_URL + 'employees/delcert?idemp='+ this.editedItemCertificate.idemp +'&memberno=' + this.editedItemCertificate.memberno, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
            })
            .then((response) => {
                console.log(response.data);
                // this.certificate.splice(this.editedIndexCertificate, 1)
                this.closeDeleteCertificate()
                bus.$emit('changeIt', 'loadProfile');
            })
            .catch(error => {
                console.log(error);
        })
      },

      closeCertificate () {
        this.dialogCertificate = false
        this.$nextTick(() => {
          this.editedItemCertificate = Object.assign({}, this.defaultItemCertificate)
          this.editedIndexCertificate = -1
        })
      },

      closeDeleteCertificate () {
        this.dialogDeleteCertificate = false
        this.$nextTick(() => {
          this.editedItemCertificate = Object.assign({}, this.defaultItemCertificate)
          this.editedIndexCertificate = -1
        })
      },

      saveCertificate () {
        if (this.editedIndexCertificate > -1) {
            // Object.assign(this.certificate[this.editedIndexCertificate], this.editedItemCertificate)
            const payload = {
                id: this.editedItemCertificate.id,
                idemp: this.editedItemCertificate.idemp,
                organisation: this.editedItemCertificate.organisation,
                memberno: this.editedItemCertificate.memberno,
                cert: this.editedItemCertificate.cert
            }

            axios.put(process.env.VUE_APP_API_URL + 'employees/editcert', payload , {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
                })
                .then((response) => {
                    console.log(response.data);
                    bus.$emit('changeIt', 'loadProfile');
                })
                .catch(error => {
                    console.log(error);
            });
        } else {
            this.certificate.push(this.editedItemCertificate)

            const payload = {
                idemp: this.mode.idEmp,
                organisation: this.editedItemCertificate.organisation,
                memberno: this.editedItemCertificate.memberno,
                cert: this.editedItemCertificate.cert
            }

            axios.post(process.env.VUE_APP_API_URL + 'employees/addcert', payload , {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
                })
                .then((response) => {
                    console.log(response.data);
                    bus.$emit('changeIt', 'loadProfile');
                })
                .catch(error => {
                    console.log(error);
            });
        }
        this.closeCertificate()
      },

      viewCert(link){
            window.open(
                link,
                '_blank'
            );
        },

      fileUploadOnChange(file,type){

        const formData =  new FormData();

        formData.append('files', file);

        axios.post(process.env.VUE_APP_API_URL + 'employees/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
        })
        .then((response) => {

            if (type == 'qualify'){
                this.editedItemQualify.cert = response.data.filepath;
            } else if (type == 'certificate') {
                this.editedItemCertificate.cert = response.data.filepath;
            }
            
        })
        .catch(error => {
            console.log(error);
        });
      },


      updateHRInfo(){
            
          const payload = {
            idemp: this.mode.idEmp,
            epfno: this.financeInfo.epfno,
            socsono: this.financeInfo.socsono,
            lhdnno: this.financeInfo.lhdnno,
            accbankno: this.financeInfo.accbankno,
            namebank: this.financeInfo.namebank
        };

        axios.put(process.env.VUE_APP_API_URL + 'employees/edit4', payload, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.token,
            }
        })
        .then((response) => {
            // console.log(response);
            this.snackbar = true;
            this.messagetext = response.data.message != 'edited' ? response.data.message : 'HR Info successfully updated!';
            this.messagecolor = 'success';
            bus.$emit('changeIt', 'loadProfile');
            this.$emit('updatedData','Yes');
        })
        .catch(error => {
            console.log(error);
        })

      },
      
    },

    mounted() {
      // this.getHrInfo();
    }
}
</script>